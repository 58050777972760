<script>
export default {
  name: 'base-radio',
  model: {
    prop: 'vModelValue',
    event: 'input',
  },
  props: {
    value: {
      type: null,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    vModelValue: {
      type: null,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: '',
    },
    hideInput: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: String,
      default: 'left',
      validator: (v) => !v || /left|right/.test(v),
    },
  },
  computed: {
    isActive() {
      if (this.value && this.vModelValue) {
        return this.value === this.vModelValue
      } else {
        return this.checked
      }
    },
    radioName() {
      return this.name ? this.name : `radio-${this.vModelValue}`
    },
  },
  methods: {
    onChange() {
      this.$emit('input', this.value)
    },
  },
}
</script>

<template>
  <label
    class="relative -ml-2.5 flex cursor-pointer items-center rounded-md px-0 hover:bg-eonx-neutral-50"
    :class="{
      'py-2.5': !hideInput,
      'flex-row-reverse': direction === 'right',
      'pointer-events-none': disabled,
    }"
  >
    <input
      class="form-radio h-4 w-4 cursor-pointer border-2 border-solid text-primary hover:border-primary focus:ring-2 focus:ring-primary"
      :checked="isActive"
      :name="radioName"
      :disabled="disabled"
      type="radio"
      :class="{
        'border-7 border-primary': isActive,
        'border-2': !isActive,
        hidden: hideInput,
        'ml-0 mr-3': direction === 'left',
        'ml-3 mr-0': direction === 'right',
      }"
      :style="{
        padding: 0,
        height: '1rem',
      }"
      @change="onChange"
    />
    <span
      class="block w-full whitespace-nowrap break-words text-base leading-tight"
      :class="{
        'text-fg-disabled': disabled,
        'text-eonx-neutral-800': !disabled,
      }"
    >
      <slot />
    </span>
    <span
      v-if="centered"
      class="w-5 shrink-0"
      :class="{
        hidden: hideInput,
        'ml-5': !hideInput,
      }"
    />
  </label>
</template>
